<template>
  <div class="">
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'societies-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label for="fullName">Nom complet</label>
        <div>
          {{ societies.fullName }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="capitalization">Capitalisation</label>
        <div>
          {{ societies.capitalization }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="city">Ville</label>
        <div>
          {{ societies.city }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="postalCode">Code postal</label>
        <div>
          {{ societies.postalCode }}
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <label for="phoneNumberA">Téléphone (1)</label>
        <div>
          {{ societies.phoneNumberA }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="phoneNumberB">Téléphone (2)</label>
        <div>
          {{ societies.phoneNumberB }}
        </div>
      </div>

      <div class="col-md-3">
        <label for="fax">Fax</label>
        <div>
          {{ societies.fax }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="cnss">Numéro CNSS</label>
        <div>
          {{ societies.cnss }}
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <label for="ice">Numéro ICE</label>
        <div>
          {{ societies.ice }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="rc">Numéro RC</label>
        <div>
          {{ societies.rc }}
        </div>
      </div>

      <div class="col-md-3">
        <label for="if">Numéro IF</label>
        <div>
          {{ societies.if }}
        </div>
      </div>
      <div class="col-md-3">
        <label for="tp">Numéro TP</label>
        <div>
          {{ societies.tp }}
        </div>
      </div>
    </div>
    <div class="row"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  societies() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("society", {
      societies: "getSocieties",
    }),
  },
  mounted() {
    this.$store.dispatch("society/fetchSocieties");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("society/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("society/fetchSocieties");
    },
  },
};
</script>
